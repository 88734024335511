<template>
  <div class="ui fluid container">
    <div class="ui middle aligned center aligned equal width grid">
      <div class="two column row">
        <div class="column">
          <img style="width: 40%;" src="@/assets/header.png" />
          <div style="width: 70%; margin: 0 auto; text-align: left;">
            <br />
            <h1 style="padding-left: 15%;">
              Order and sell promotional products<br />and corporate wearables
            </h1>
          </div>

          <img style="width: 30%;" src="@/assets/mockup.png" />
          <br /><br /><br /><br /><br /><br /><br /><br />
          <p style="font-family: 'HelveticaNeueCondensedBold'">
            powered by
            <img
              style="vertical-align: middle; width: 80px;"
              src="@/assets/pixels.png"
            />
          </p>
        </div>
        <div class="login column">
          <div style="margin: 0 auto; max-width: 70%">
            <div style="text-align: left;">
              <h1>Sign in</h1>
            </div>
            <br /><br />
            <div
              v-if="$route.query.error || formError?.non_field_errors"
              class="ui error message"
            >
              <ul class="list">
                <li v-if="$route.query.error">{{ $route.query.error }}</li>
                <li v-if="formError?.non_field_errors">
                  {{ formError.non_field_errors[0] }}
                </li>
              </ul>
            </div>
            <form class="ui form" id="loginForm" style="text-align: left;">
              <div class="ui error message"></div>
              <div class="field">
                <label>Email</label>
                <input
                  name="email"
                  v-model="credentials.username"
                  placeholder="john@corporate.com"
                />
              </div>
              <div class="field">
                <label>Password</label>
                <input
                  name="password"
                  v-model="credentials.password"
                  type="password"
                  placeholder="******"
                />
              </div>
              <button
                :class="{ loading: loading.submit }"
                :disabled="loading.submit"
                class="ui primary button"
                type="submit"
              >
                Sign In
              </button>
            </form>
            <br /><br /><br />
            <button @click="onMicrosoftClick" class="ui microsoft button">
              <i class="microsoft icon"></i>
              Sign in with Microsoft
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/auth";
import CryptoJS from "crypto-js";
import { mapActions } from "vuex";

const $ = window.$;

export default {
  name: "Login",

  data() {
    return {
      formError: null,
      credentials: {
        username: null,
        password: null
      },
      loading: {
        submit: false
      }
    };
  },

  methods: {
    async signIn() {
      this.loading.submit = true;
      try {
        const response = await this.$http.post("auth-jwt/", this.credentials);
        auth.login(this.credentials.username, response.data.token);
        this.loadProfile();
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        if (error.response?.data) {
          this.formError = error.response.data;
        } else {
          $("#loginForm .error.message")
            .html(
              "Network error. Please check your conectivity or try again later."
            )
            .show();
        }
      } finally {
        this.loading.submit = false;
      }
    },

    onMicrosoftClick() {
      // function generateCodeVerifier() {
      //   return generateRandomString(128);
      // }
      // function generateRandomString(length) {
      //   var text = "";
      //   var possible =
      //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
      //   for (var i = 0; i < length; i++) {
      //     text += possible.charAt(Math.floor(Math.random() * possible.length));
      //   }
      //   return text;
      // }
      function generateCodeChallenge(code_verifier) {
        return base64URL(CryptoJS.SHA256(code_verifier));
      }

      function base64URL(string) {
        return string
          .toString(CryptoJS.enc.Base64)
          .replace(/=/g, "")
          .replace(/\+/g, "-")
          .replace(/\//g, "_");
      }

      let code_verifier = "BB4Ktnz9PCkjmtDWu9ybifAw7GaTbwIVVSosnOENJUWUQlKS"; // generateCodeVerifier();
      window.sessionStorage.setItem("cv", code_verifier);
      let code_challenge = generateCodeChallenge(code_verifier);

      const popupUrl =
        process.env.NODE_ENV === "development"
          ? `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=79e5d74f-55be-4ba8-8203-171b57d78c23&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth-login%2F&response_mode=query&scope=offline_access%20user.read&state=12345&code_challenge=${code_challenge}&code_challenge_method=S256`
          : `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=79e5d74f-55be-4ba8-8203-171b57d78c23&response_type=code&redirect_uri=https%3A%2F%2Fom.pixelshive.com%2Fauth-login%2F&response_mode=query&scope=offline_access%20user.read&state=12345&code_challenge=${code_challenge}&code_challenge_method=S256`;

      window.location.href = popupUrl;
      // window.open(
      //   popupUrl,
      //   "toolbar=no,menubar=no,width=600,height=700,top=100,left=100"
      // );
    },

    ...mapActions(["loadProfile"])
  },

  mounted() {
    let vm = this;

    $("#loginForm").form({
      fields: {
        email: "empty",
        password: "empty"
      },
      onSuccess(event) {
        event.preventDefault();
        delete vm.$route.query.error;
        vm.signIn();
      },
      onFailure() {
        delete vm.$route.query.error;
        vm.formError = null;
        return false;
      }
    });
  }
};
</script>

<style scoped>
.login.column {
  background-color: #c3cfd8;
  padding-top: 5em !important;
  height: 100vh !important;
}

.microsoft.button {
  font-weight: bold;
  background-color: #2f2f2f;
  color: #fff;
  border-radius: 0 0 0 0;
}

.microsoft.button:hover {
  background-color: #fff;
  color: #5e5e5e;
}
</style>
